<template>
  <div class="app">
        <form class="auth-form" @keypress.enter.prevent>
            <h1>Welcome Back</h1>
            <div class="auth-site-name">afkadvantage.com</div>
            
            <div class="auth-input">
                <label for="inputEmail">Email Address</label>
                <input v-model="email" type="email" placeholder="Email Address" id="inputEmail">
            </div>

            <div class="auth-input">
                <label for="inputPassword">Password</label>
                <input v-model="password" type="password" placeholder="Password" id="inputPassword">
                <router-link class="auth-input-href" to="/auth/forgot-password">Forgot password?</router-link>
            </div>

            <VueRecaptcha ref="captchaChallenge" @verify="verifyCaptcha" sitekey="6LewO_4ZAAAAADFPZr2MTzje0P1Z9kq5u-7rF648"/>

            <div class="auth-input">
                <button type="button" v-on:click="login" class="button-primary-2" v-bind:disabled="isLoading">Log in</button>
            </div>

            <div class="auth-switch-site">Don't have an account? <router-link to="/auth/register">Sign up</router-link></div>
        </form>
    </div>
</template>

<script>

  import Api from '../../scripts/api';
  import Toast from '../../scripts/toast';

  import VueRecaptcha from 'vue-recaptcha';
  export default {
    components: { VueRecaptcha },
    data() {
      return {
        email: '',
        password: '',
        captcha: '',
        isLoading: false
      }
    },
    methods: {
      login: async function() {
        
        if(!Api.validation.validateEmail(this.email)) {
          return Toast.error('Email is invalid.');
        }
        if(!Api.validation.validatePassword(this.password)) {
          return Toast.error('Password must be at least 6 characters.');
        }
        if(!Api.validation.validateCaptcha(this.captcha)) {
          return Toast.error('Please complete the captcha.');
        }

        this.isLoading = true;
        try {

          var response = await Api.authLogin(this.email, this.password, this.captcha);
          if(response.data.success) {
            window.location.replace("/");
          } else {
            switch(response.data.reason) {
              case 0: Toast.error('Invalid email address provided.'); break;
              case 1: Toast.error('Invalid password provided.'); break;
              case 2: Toast.error('Invalid captcha value, try again.'); break;
              default: Toast.error('Email or password did not match our records.')
            }
            this.password = '';
            this.captcha = '';
            this.$refs.captchaChallenge.reset();
            this.isLoading = false;
          }

        } catch (ex) {
          console.log(ex);
          Toast.error('Unexpected error occured, try again.')
          this.password = '';
          this.captcha = '';
          this.$refs.captchaChallenge.reset();
          this.isLoading = false;
        }
      },
      verifyCaptcha: function(response) {
        this.captcha = response;
      }
    }
  };
</script>

<style scoped>
  @import '../../assets/css/auth.css';
</style>